@import "../../../styleSheets/globalColors.scss";

label.group-menu-label.group-menu-accounting-book:focus-within  {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

label.group-menu-label.group-menu-accounting-book:hover,
label.group-menu-label.group-menu-accounting-book:active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

label.group-menu-label.group-menu-accounting-book::before {
  content: '';
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

label.group-menu-label.group-menu-accounting-book:first-child {
  border-top: 1px solid #eee;
}

label.group-menu-label.group-menu-accounting-book {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transition: .3s;
  padding-left:  20px;
  padding-right: 20px;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;

  .group-menu-date {
    font-weight: 400;
    font-size: 12px;
    width: 38px;
  }

  .group-menu-date.active {
    display: none;
    transform: translate(-10px, 0px);
    font-weight: 400;
    width: 52px;
  }

  .group-menu-image-block.active {
    transform: translate(-10px, 0px);
  }

  .group-menu-image-block {
    transition: .3s;
    transform: translate(0px, 0px);

    img {
      width: 28px;
      height: 28px;
    }
  }

  .group-menu-payment-block.active {
    transform: translate(-10px, 0px);
  }

  .group-menu-payment-block {
    height: 100%;
    transition: .3s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .group-menu-checkbox {
    padding-right: 10px;
  }

  .group-menu-username {
    border: none;
    display: flex;
    flex-flow: column nowrap;
    line-height: 1rem;
    justify-content: center;
    padding: 0px 13px;
    text-align: left;
    flex-shrink: 1;
    flex-grow: 1;

    .description {
      padding-bottom: 4px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;

    }
    .message {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      color: #909090;
    }
  }
  .group-menu-amount {
    height: 100%;
    z-index: 5;
    color: $green300;
    flex-grow: 1;
    text-align: right;
    text-overflow: ellipsis;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    overflow: hidden;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
  }

  .group-menu-amount.active {
    display: none;
  }


  .group-menu-username.disabled {
    border-bottom: 1px solid transparent;
  }

  .group-menu-username.select-all {
    position: relative;
  }

  .group-menu-username.select-all > div {
    position: relative;
    bottom: 4px;
  }

  .btn-group.active {
    background-color: #fff;
    transform: translate(0px, 0px);
  }

  .btn-group {
    right: 0;
    position: absolute;
    width: 100px;
    height: 50px;
    line-height: 70px;
    transition: .3s;
    transform: translate(100px, 0px);
    display: flex;
    justify-content: center;
  }

  button {
    background-color: $gold500;
    width: 35px;
    padding: 5px 0px;
    background: none;
    background-color: transparent;
    border: none;
    outline: none;

    .fa-edit {
      color: $gold900;
    }
    .fa-trash {
      color: $gold900;
    }
  }

  button:active,
  button:hover,
  button:focus {
    outline: none;
    background: none;
    background-color: none;
     -webkit-appearance: none;
    -moz-appearance: none;
  }

  button:active .fa-trash,
  button:active .fa-edit {
    color: $gold700;
  }
}

.book.collapse {
  border-bottom: 1px solid #eee;
  position: relative;
  color: $gray900;
  margin: 0px 20px 10px;
  text-align: right;

  .btn-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .icon {
      font-size: 13px;
      width: 80px;
      padding: 5px 0px;
      margin: 0px 5px;
      background: $gold900;
      background-color: transparent;
      color: $gold900;
      transition: .3s;
    }
    .icon.delete {
      width: 80px;
    }
    .icon.switch {
      width: 100px;
    }

    .icon:active {
      background: $gold700;
    }
  }
}
