$green900: #103c2b;
$green700: #0c533c;
$green500: #07694d;
$green300: #6fb09e;
$green100: #F7FBFA;
$gold900:  #88631c;
$gold700:  #a58341;
$gold500:  #c5af85;
$gold300:  #f1ece4;
$gray900:  #2d2d2d;
$gray700:  #6f6f6f;
$gray600:  #909090;
$gray500:  #cccccc;
$gray400:  #ececec;
$gray300:  #f2f2f2;
$gray100:  #f6f6f6;
$graycore: #000000;
$black:    #2D2F30;
$white:    #FFFFFF;
$invalid:  #D65C5C;
$red:      #F9676A;

$dark-green: #194D39;
$primary-green: #126951;
$light-green: #71A597;

$dark-gold: #A58341;
$primary-gold: #C5AF85;
$light-gold: #E5DBC8;

$small: 321px;
$medium: 768px;
