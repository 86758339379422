.imageBlock {
  display: flex;
  vertical-align: bottom;
}

.image {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: bottom;
}

.children {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
