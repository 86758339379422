@import "../../../styleSheets/globalColors.scss";

.fixedHeader {
  width: 100%;
  z-index: 105;
  transition: .4s;
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  align-items: center;
  overflow: 'hidden';
  background-color: white;

  height: 58px;
  top: 0;
  width: 100%;
  align-items: center;
  z-index: 100;
  padding-left: 38px;

  .name {
    padding-right: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.header {
  width: 100%;
  transition: .2s;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: 'hidden';
  background-color: transparent;
}

.header.small {
  background: linear-gradient(to bottom,  rgba(255, 255, 255, 1) 0%,  rgba(233, 233, 233, 0) 90%, rgba(233, 233, 233, 0) 100%);
  height: 0px;
  position: absolute;
  opacity: 0;
  top: -50px;
  width: 100%;
  align-items: center;
  z-index: 100;
  padding-left: 0px;

  .searchBox {
    display: none;
  }

  .innerBlock {
    flex-flow: row;
    color: $gold900;
    padding-left: 0px;

    .bookIcon {
      transition: .3s;
      font-size: 20px;
      margin-right: 20px;
      padding-bottom: 0px;
    }
    .name {
      color: black;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
    }

  }
}

.searchBox {
  width: calc(90% - 20px);
  margin: 20px 10px 0px;
  padding: 0px 10px;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 15px;
  display: flex;
  align-items: center;

  .searchIcon {
    font-size: 20px;
  }

}

.innerBlock {
  text-align: center;
  padding: 20px;
  text-align: left;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .textBlock {
    display: flex;
    flex-flow: column;
  }

  .bookIcon {
    margin-right: 24px;
    font-size: 50px;
  }

  .name {
    padding-bottom: 5px;
    color: $gold900;
    font-size: 1.5rem;
  }

  .info {
    padding: 0px 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .label {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      color: $gray600;
    }
    .count {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

}

.barsIcon {
  transition: .3s;
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 10px
}

