@import "../../../styleSheets/globalColors.scss";

.container {
  position: absolute; /* or absolute */
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 60;

  display: flex;
  flex-flow: column;
  width: calc(100% - 48px - 48px);
  background-color: #fff;
  border-radius: 16px;
}

.btnGroup {
  display: flex;
  flex-flow: row;
}
.children {
  width: 100%;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}

.title {
  padding: 24px 0px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2D2D2D;

}

.button {
  border: none;
  width: 100%;
  padding: 12px 0px;
  font-size: 16px;
  color: white;
  font-weight: 600;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  font-style: normal;
  font-weight: bold;
  background-color: #fff;
  font-size: 16px;
}

.confirm {
  color: #88631C;
  border-bottom-left-radius: 0px;
}

.cancel {
  color: #2D2D2D;
  border-bottom-right-radius: 0px;
}

.button[disabled] {
  background: $gray100;
}

button:focus,
button:active {
  outline: none;
}

button:active {
  outline: none;
}
