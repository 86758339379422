@import '../../styleSheets/globalColors.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.strokeWhite {
  path {
    stroke: $white;
  }
}

.white {
  path {
    fill: $white;
  }
}

.gold700 {
  path {
    fill: $gold700;
  }
}

.accountingGold {

  path {
  }

  path {
    fill: $gold900;
  }

  path ~ path {
    stroke: $gold900;
    fill: transparent;
  }

  rect, circle {
    stroke: $gold900;
  }
}
.gold900 {
  path {
    fill: $gold900;
  }
}
.gray700 {
  path {
    fill: $gray700;
  }
}

.black {
  path {
    fill: $black;
  }
}

.green500 {
  path {
    fill: $green500;
  }
}

.red {
  path {
    fill: $red;
  }
}

.invalid {
  path {
    fill: $invalid;
  }
}
