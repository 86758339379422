@import "../../../styleSheets/globalColors.scss";

.button {
  cursor: pointer;
  border: none;
  width: 100%;
  padding: 12px 0px;
  border-radius: 100px;
  font-size: 16px;
  color: white;
  font-weight: 600;
}

.button[disabled] {
  background: $gray500;
}

.button[disabled]:active {
  background: $gray500;
}

button:focus,
button:active {
  outline: none;
}

button:active {
  outline: none;
}

.green {
  background: linear-gradient(92.29deg, #103C2B 0%, #07694D 100%);
}

.green:active {
  background: linear-gradient(92.29deg, #103C2B 40%, #07694D 100%);
}

.gold {
  background: $gold300;
}

.gold:active {
  background: $gold500;
}

.red {
  background: $red;
}

.red:active {
  background: $red;
}
