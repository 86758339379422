.container {
  cursor: pointer;
  width: 100%;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
}

.label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
