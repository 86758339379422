.imageBlock {
  display: flex;
  vertical-align: bottom;
}

.image {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: bottom;
}
