@import '../../../styleSheets/globalColors.scss';

.container {
  height: 100%;
  flex-flow: column;

  background-color: #ffffff;
  z-index: 300;
  width: 100%;
  position: absolute;
  text-align: left;
  overflow: auto;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;

  form {
    padding: 20px;
    border-radius: 5px;
    margin: auto;
    width: 80vw;
    max-height: calc(80vh - 66px);
    height: calc(80vh - 66px);
    background-color: #fff;
  }
}

.title {
}

.labels {
  position:relative;
  width: 100%;
  height: calc(100vh - 58px);
  overflow: auto;
  font-weight: 200;
}

.header {
  max-height: 65px;
  height: 65px;
  padding: 17px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
}

.headerLabel {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: black;
  align-items: center;
}

.checkboxClose {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: $gold700;
  cursor: pointer;
}
