@import "../../../styleSheets/globalColors.scss";

.container {
  position: absolute;
  bottom: 0;
  height: calc(100vh - 66px);
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 100;
  overflow: hidden;
  transform: translateY(100%);
  transition: .2s;
}

.container.mount {
  transform: translateY(0%)
}

.labels {
  position:relative;
  width: 100%;
  height: calc(100vh - 66px - 41px - 38px);
  overflow: auto;
}

.checkboxLabels {
  position:relative;
  width: 100%;
  height: calc(100vh - 66px - 41px - 48px - 32px);
  overflow: auto;

}
