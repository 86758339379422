@import "../../../styleSheets/globalColors.scss";

.header {
  z-index: 30;
  padding: 16px 20px 17px;
  width: 100%;
  min-height: 58px;
  height: 58px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  background: #ffffff;
}

.innerBlock {
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  color: $gold900;

  .icon {
    transition: .3s;
    color: $gold900;
    font-size: 20px;
    margin-right: 20px;
    padding-bottom: 0px;
  }
  .name {
    display: flex;
    color: black;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

  }
}
