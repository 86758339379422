.container {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.labels {
  overflow: auto;
  flex-shrink: 1;
}
