@import '../../../styleSheets/globalColors.scss';

.container {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
  color: $gray900;
}

.lint {
  padding-left: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: $green500;
}
