@import "../../../styleSheets/globalColors.scss";

.header {
  position: relative;
  min-height: 200px;
  transition: .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(90deg, rgba(16,60,43,1) 0%, rgba(7,105,77,1) 100%);
}

.header.small {
  min-height: 58px;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.innerBlock {
  flex-flow: column;
  text-align: left;
  display: flex;
  align-items: center;

  .bookIcon {
    font-size: 60px;
    padding-bottom: 20px;
  }

  .name {
    padding-bottom: 10px;
    color: $gray400;
    font-size: 1.5rem;
  }
  .details {
    font-size: 12px;
    font-weight: 500;

    .user_count {
      color: #eee;
    }
  }

}

.innerBlock.small {
  flex-flow: row;

  .bookIcon {
    transition: .3s;
    font-size: 20px;
    margin-right: 20px;
    padding-bottom: 0px;
  }

  .name {
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
    padding: 0;
  }

  .details {
    .user_count {
      display: none;
      font-size: 0.8rem;
    }
  }
}


.barsIcon {
  transition: .3s;
  position: absolute;
  right: 10px;
  top: 10px
}

