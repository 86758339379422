@import "../../../styleSheets/globalColors.scss";

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 100;
  overflow: hidden;
  transition: .2s;
}

.container.mount {
}
