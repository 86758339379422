@import "../../../styleSheets/globalColors.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 264px;
  max-width: 264px;
  width: 100%;
  height: 36px;
  margin-bottom: 24px;
  -webkit-tap-highlight-color: transparent;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray100;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 36px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: calc((100% - 8px) / 2);
  left: 4px;
  bottom: 4px;
  background: rgb(136,99,28);
  background: linear-gradient(90deg, rgba(136,99,28,1) 0%, rgba(197,175,133,1) 100%);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 36px;
}

input:focus + .slider {
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc((200% - 8px) / 2));
  -ms-transform: translateX(calc((200% - 8px) / 2));
  transform: translateX(calc((200% - 8px) / 2));
  border-radius: 36px;
}

.labelName {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: black;
  z-index: 1;
  font-size: 14px;
  font-weight: 900;
}

.labelName:before {
  position: absolute;
  content: "";
  height: 28px;
  width: calc((100% - 8px) / 2);
  left: 4px;
  bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gold900;
  font-weight: 900;
}

.labelName.self:before {
  content: "自己分";
  transform: translateX(calc((200% - 8px) / 2));
}

.labelName.auto:before {
  content: "平分";
  color: #FFFFFF;
}

input:checked ~ .labelName.self:before {
  color: #FFFFFF;
}

input:checked ~ .labelName.auto:before {
  color: $gold900;
}

