@import "../../../styleSheets/globalColors.scss";

.container {
  padding: 0px 16px;
  color: $gray600;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
}
