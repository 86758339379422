@import "../../../styleSheets/globalColors.scss";

.container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
  border-radius: 16px;
  background-color: #fff;
}

.label:focus-within  {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

.label {
  padding: 0px 20px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid transparent;
  font-size: 16px;
  transition: .2s;
  background-color: $gray100;
}

.label.invalid {
  border: 1px solid #D65C5C;
  border-radius: 16px;
}

.labelName {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gold900;
}
.faIcon {
  min-width: 18px;
  max-width: 18px;
  display: flex;
  justify-content: center;
}

.name {
  font-weight: 900;
  color: $gold900;
  font-size: 16px;
  min-width: 60px;
  padding: 0px 12px;
  font-style: normal;
  font-weight: 700;
}

.input {
  background-color: $gray100;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  transition: .4s;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 0px;
  color: $gray900;
  padding-top: 1px;
}

.input[disabled] {
  background-color: transparent;
  border-radius: none;
}

.input, .input:focus {
  border: none;
  outline: none;
}

.input::placeholder {
  color: $gray500;
}

.invalidFeedback {
  color: $invalid;
  font-size: 12px;
  border-radius: 0;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
}
