@import "../../../styleSheets/globalColors.scss";

.container {
  width: 100%;
  overflow: hidden;
  overflow: hidden;
  outline: 1px solid red !important;
}

.label {
  outline: 1px solid red !important;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 54px;
  display: flex;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #eee;
  font-size: 16px;
  transition: .2s;
}

.label.invalid {
  border: 1px solid rgb(214 92 92);
  border-radius: 16px;
  box-shadow: 0 0 0 2px rgba(214, 92, 92, 0.2);
}

.name {
  width: 100px;
  font-weight: 900;
  border-right: 1px solid #eee;
  color: $gold900;
  line-height: 52px;
}

.input {
  font-size: 16px;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  transition: .4s;
}

.input[disabled] {
  background-color: #eee;
  border-radius: none;
}

input::placeholder {
  color: $gray500;
}

.invalidFeedback {
  font-weight: 500;
  padding: 8px 0px 4px;
  text-align: right;
  color: red;
  font-size: 12px;
}
