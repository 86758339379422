@import "../../../styleSheets/globalColors.scss";

.bg {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
  height: 60px;
  text-align: center;
  background: none;
  background-color: white;
}

.icons {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-around;

  a {
    color: $gray600;
    font-weight: 500;
    flex-grow: 1;
    background-color: transparent;

    span {
      display: inline-block;
      font-size: 13px;
      padding-top: 10px;
    }
  }

  a.disabled {
    pointer-events: none;
  }

  .fa {
    font-size: 15px;
    color: $gray900;
  }

  .icon {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  a.icon:active {
//     color: white;
//     background-color: $green700;

    .fa {
      color: white;
    }
  }

}

a.hover,
a.active {
  color: white;
}

.icon.activeStyle {
  color: black;
//   background-color: $green700;
}
.fa.activeStyle {
  color: black;
//   color: white;
}


