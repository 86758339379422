.floatingIcon {
  position: absolute;
  z-index: 10;
}

.Icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  transition: .05s;
}

.avatar {
  border-radius: 50%;
}
