@import "../../../styleSheets/globalColors.scss";

.value {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  color: $gray900;
 }

 .input::-webkit-calendar-picker-indicator {
   color: white;
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   margin: 0;
   padding: 0;
   cursor: pointer;
 }

.input:disabled {
  background-color: transparent;
}

.input::placeholder {
  color: $gray900;
}
