@import '../../styleSheets/globalColors.scss';

.container {
  cursor: pointer;
  color: $gold700;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
