@import "../styleSheets/globalColors.scss";

.group-menu-checkbox-label {
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.checkmark {
  background-color: #eee;
}

.group-menu-checkbox-label:hover input ~ .checkmark {
  background-color: #eee;
}

.group-menu-checkbox-label input:checked ~ .checkmark {
  background-color: $green900;
}

.group-menu-checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

.group-menu-checkbox-label .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
