.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  height: 100vh;
  z-index: 50;

  .icon {
    position: absolute;
    right: 28px;
    top: 26px;
  }
}
