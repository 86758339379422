@import '../../../styleSheets/globalColors.scss';

.container {
  margin-bottom: 12px;
}

.invalid {
  border: 1px solid red;
  border-radius: 16px;
  margin-bottom: 0px;
}

.label {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;

  height: 80px;
  background: #E6EFED;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.invalidLabel {
  border: 1px solid red;
}

.icon {
  display: flex;
}

.name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: $gray900;
  text-align: left;
}

.invalidFeedback {
  color: $invalid;
  font-size: 12px;
  border-radius: 0;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
}
