@import "../../../styleSheets/globalColors.scss";

.wiggle {
  z-index: 2;
  display: block;
  width: 50%;
  height: 2px;
  background-color: $gold700;
  transition: .2s;
}

.wiggle.active {
  transform: translateX(100%);
}

.bg {
  padding: 0px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  background: none;
  background-color: white;
}

.icons {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-around;

  .icon {
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    color: $gray600;
    font-weight: 600;
    flex-grow: 1;
    background-color: transparent;

    span {
      display: inline-block;
      font-size: 14px;
      padding-top: 10px;
    }
  }

  .icon.disabled {
    pointer-events: none;
  }

  .fa {
    font-size: 15px;
    color: $gray900;
  }

  .icon {
    padding-top: 12px;
    padding-bottom: 11px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .icon.activeStyle {
    color: $gold700;
  }
}

a.hover,
a.active {
  color: white;
}

.icon.activeStyle {
  color: black;
//   background-color: $green700;
}
.fa.activeStyle {
  color: black;
//   color: white;
}


