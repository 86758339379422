@import "../../../styleSheets/globalColors.scss";

input {
  -webkit-border-radius: 0;
}

.search-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  vertical-align: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #eee;
  margin: 16px 20px;
  background-color: #eee;
  padding: 12px 12px;
  border-radius: 20px;
  color: $gray600;

  input.search {
    background-color: #eee;
    flex-grow: 2;
    border: none;
  }

  input.search::placeholder {
    color: $gray600;
  }

  input.search:focus {
    box-shadow: none;
    border: none !important;
    outline: 0 none;
  }

  label {
    display: flex;
    flex-grow: 1;
    text-align: left;
    margin-bottom: 0px;
    flex-flow: row nowrap;
    align-items: center;
  }
}
.search {
  padding: 0px 10px;
  border: none;
  font-size: 14px;
}
.fa-times-block {
  color: $gray600;
  padding: 0px 10px;
  display: inline-block;
  font-size: 15px;
}
.fa-times:hover {
  position: relative;
  color: #333;
}
.fa-search {
  position: relative;
  color: #ccc;
}
.search:focus {
  outline: none;
}

