@import "../styleSheets/globalColors.scss";

// label.group-menu-label.group-menu-summary:focus-within  {
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
// }

label.group-menu-label.group-menu-summary.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

// label.group-menu-label.group-menu-summary::before {
//   content: '';
//   width: 10px;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   background-color: $gold900;
// }

label.group-menu-label.group-menu-summary {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  background-color: $gray100;
  position: relative;
  overflow: hidden;
  transition: .3s;
  margin: 12px 16px 0px;
  padding-left:  12px;
  padding-right: 12px;
  height: 70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #ECECEC;
  border-bottom: none;


  .group-menu-image-block {
    transition: .3s;
    transform: translate(-10px, 0px);

    img {
      width: 40px;
      height: 40px;
    }
  }

  .group-menu-payment-block {
    transform: translate(-10px, 0px);
    transition: .3s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .group-menu-checkbox {
    padding-right: 10px;
  }

  .group-menu-username {
    border: none;
    display: flex;
    flex-flow: column nowrap;
    line-height: 1rem;
    justify-content: center;
    padding: 0px 10px;
    text-align: left;
    flex-grow: 1;
    flex-shrink: 1;

    .description {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      color: $gold900;
    }
  }
  .group-menu-amount {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: $gray700;
    text-align: left;
  }
}

.user-summary.collapse {
  margin-bottom: 100px;
  background-color: $gray100;
  position: relative;
  font-size: 13px;
  color: $gray900;
  margin: 0px 16px 0px;
  padding: 10px 16px 5px;
  padding-top: 0px;
  padding-right: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ECECEC;
  border-top: none;

  .allocations {
    padding-left: 37px;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;


    .allocationInner {
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
    }

    .allocation::before {
      content: '';
      padding-right: 2px;
      background-color: #eee;
      display: inline-block;
      position: relative;
      top: 5px;
      width: 12px;
      height: 1px;
      border-bottom: 0px solid $gray400;
    }
  }

  .allocation {
    display: flex;
    padding: 8px 0px;
    text-align: left;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }

    .name{
      text-align: left;
      flex-grow: 1;
      flex-shrink: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 60px;
      display: block;
      overflow: hidden
    }

    span.amount {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }

    .paidbackBtn {
      cursor: pointer;
      min-width: 74px;
      padding: 0px 22px;
      font-size: 14px;
      font-weight: bold;
      color: $gold900;
    }
  }

  .allocation.title {
    padding: 3px 10px;
  }

  .allocation.buttons {
    padding: 0;
    border-top:  1px solid $gray400;

    display: flex;
    justify-content: flex-end;

    .btn {
      padding: 12px 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      padding-right: 16px
    }
    .btn.edit {
      color: $gold900;
    }
  }
}
