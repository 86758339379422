@import "../../../styleSheets/globalColors.scss";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.separater {
  width: 100%;
  height: 1px;
  border-top: 1px solid $gray400;
  margin: 11px 20px;
}
