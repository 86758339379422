@import "../../../styleSheets/globalColors.scss";

.label.invalid {
  border: 1px solid rgb(214 92 92);
  border-radius: 16px;
  box-shadow: 0 0 0 2px rgba(214, 92, 92, 0.2);
}

.label.focus {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

.label {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  width: 100%;
  height: 44px;
  display: flex;
  background-color: #ffffff; text-align: center;
  border-radius: 16px;
  border: 1px solid #eee;
  font-size: 14px;
  transition: .2s;
}

.name {
  font-weight: 900;
  border-right: 1px solid #eee;
  width: 100px;
  color: $gold900;
  line-height: 42px;
}


.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}

.value {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  box-sizing: border-box;
  color: $gray900;
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
 }

 .input::-webkit-calendar-picker-indicator {
   color: white;
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   margin: 0;
   padding: 0;
   cursor: pointer;
 }

.input:disabled {
  background-color: transparent;
}

.input::placeholder {
  color: $gray900;
}
