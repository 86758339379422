@import "./globalColors.scss";

label.group-menu-label {
  padding-left: 23px;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
  vertical-align: center;
  font-weight: 600;
  font-size: 14px;

  #select-all {
    line-height: 47px;
  }
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  display: block;
  position: relative;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: relative;
  display: none;
}

.group-menu-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form-control.is-invalid {
  background-image: none;
  background-color: transparent;
  padding-right: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
