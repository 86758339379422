.container {
  padding: 20px;
  width: 100%;
  background-color: #ffffff;
  z-index: 300;
  position: absolute;
  top:  66px;
  text-align: left;
  border-radius: 5px;
  overflow: auto;
  max-height: calc(100vh - 66px);
  height: calc(100vh - 66px);
}
