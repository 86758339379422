@import "../../../styleSheets/globalColors.scss";

.label {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
  vertical-align: center;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  display: block;
  position: relative;
  border-radius: 50%;
  border: 2px solid #CCCCCC;
  box-sizing: border-box;
}

.label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark:after {
  content: "";
  position: relative;
  display: none;
}

/* On mouse-over, add a grey background color */
.label:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.label input:checked ~ .checkmark {
  background-color: $white;
  border: 2px solid $gold700;
}

/* Show the indicator (dot/circle) when checked */
.label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.label .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $gold700;
}

