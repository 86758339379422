.container {
  background: #F1ECE4;
  color: #88631C;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center
}
