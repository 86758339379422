@import "../../../styleSheets/globalColors.scss";

.container {
  width: 100%;
  background-color: #fff;
  transform: translateY(100%);
  transform: translateY(0%);
  height: calc(100vh - 58px - 60px);
  max-height: calc(100vh - 58px - 60px);
  transition: .2s;
  padding-bottom: 50px;
}

.container.mount {
  transform: translateY(0%);
}

.labels {
  position:relative;
  width: 100%;
  height: calc(100vh - 58px - 60px);
  overflow: auto;
  font-weight: 200;
  padding: 0px 0px 80px;
}
