@import '../../../styleSheets/globalColors.scss';

.container {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 12px 16px 0px;
  background-color: transparent;
  transition: background-color 100ms;
  transition-delay: 1ms;
  font-family: 'Noto Sans TC';
  font-style: normal;
}

.container.active {
  background-color: $gray100;
  transition-delay: 200ms;
}

.label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label.active {
  border-bottom: 1px solid $gray400;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  transition: border 400ms;
}

.label {
  border-bottom: 1px solid #ECECEC;
  transition: border 100ms;
  border: 1px solid #ECECEC;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  background-color: $gray100;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 12px 16px;
  // height: 80px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.checkbox {
  position: absolute;
}

.name {
  border: none;
  display: flex;
  flex-flow: row nowrap;
  line-height: 1rem;
  text-align: left;
  flex-grow: 1;
  flex-shrink: 1;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  color: $graycore;

  margin-bottom: 5px;

  overflow: hidden;

  .description {
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    display: block;
    overflow: hidden;

    .paidBack {
      color: $green700;
    }
  }

  .message {
  }
}
.multiImage {
  display: flex;
  align-items: bottom;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  max-width: 40px;
  line-height: 22px;
  letter-spacing: 0px;
  color: $gray900;
  text-align: left;

  .countBox {
    display: flex;
    flex-flow: row;
    z-index: 100;
    align-items: end;
  }

  .countSection {
    align-self: flex-end;
    flex: 1;
  }
  .count {
    width: 20px;
    height: 20px;
    background-color: $gold700;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: $white;
  }
}

.image {
  transition: .3s;
  transform: translate(0px, 0px);

  img {
    width: 24px;
    height: 24px;
  }
}

.images {
  display: flex;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
}

.icon {
  cursor: pointer;
  display: block;
  padding: 4px 16px;

  background: #F1ECE4;
  border-radius: 100px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: $gold900;
  -webkit-tap-highlight-color: transparent;
}

.amount {
  text-align: right;
  font-size: 15px;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: $gray900;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;

  .description {
    color: $gold700;
  }

  .message {
    color: $gray700;
  }

  .amountLeft {
    display: flex;
    align-items: center;
  }
}

.paymentBlock {
  transition: .3s;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 0px 5px;
  padding-right: 0px;
  justify-content: space-between;
}


.label.paidBack {
  border-bottom: 1px solid #ECECEC;
  background: $green100;

  .name {
    .description {
      color: $graycore;
    }
  }
  .amount {
    .description {
      color: $green900;
    }
  }
}

.payment.collapse.paidBack {
  background: $green100;
  .allocation.buttons {
    padding: 0;
    border-top:  1px solid white;
  }
}


.paymentCollapse.paidBack {
  background: $green100;
  background-color: $green100;
  border-top: none;
}

.paymentCollapse {
  margin-bottom: 100px;
  background-color: $gray100;
  position: relative;
  font-size: 13px;
  color: $gray900;
  margin: 0px 16px 0px;
  padding: 10px 16px 5px;
  padding-top: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ECECEC;
  border-top: none;


  .allocations {
    padding-left: 37px;
    border: none;
    // padding-top: 8px;
    // padding-bottom: 8px;

    .allocationInner {
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
    }

  }

  .allocation.title {
    padding: 3px 10px;
  }

}

.green700 {
  color: $green700;
}

.thick {
  font-weight: 500;
}

.allocation::before {
  content: '';
  padding-right: 2px;
  display: inline-block;
  position: relative;
  top: 5px;
  width: 12px;
  height: 1px;
  border-bottom: 0px solid transparent;
}

.allocationSection {
  .allocation {
    padding: 3px 0px;
    display: flex;
    justify-content: flex-start;
    color: $gray700;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
}


.allocation {
  padding: 3px 0px;
  display: flex;
  justify-content: space-between;

  .allocationInner {
    padding: 0;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    flex-grow: 1;
  }


  .alloName {
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .alloAmount {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.buttons {
  padding: 0;
  border-top: 1px solid #ECECEC;

  display: flex;
  justify-content: flex-end;

  .btn {
    cursor: pointer;
    padding: 12px 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
  .btn.edit {
    color: $gold900;
    margin-right: -8px;
  }
}

.buttons.paidBack {
  border-top: none;
  .btn {
    margin-right: -8px;
  }
  .btn.edit {
    color: $green500;
    margin-right: -8px;
  }
}

