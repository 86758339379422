@import "../../../styleSheets/globalColors.scss";

.form {
  background-color: #ffffff;
  z-index: 300;
  width: 100%;
  position: absolute;
  text-align: left;
  overflow: auto;
  left: 0;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 20px;
  border-radius: 5px;
  margin: auto;
  width: 90vw;
  max-height: calc(218px);
  background-color: #fff;
  overflow-x: hidden;
}

.btn {
  background: linear-gradient(90deg, rgba(16,60,43,1) 0%, rgba(7,105,77,1) 100%);
  background: $green700;
  border-radius: 12px;
}

.btn:active {
  background: $green500;
}

.text {
  font-size: 15px;
  padding: 4px 4px;
}

.input {
  padding-top: 6px;
  padding-bottom: 4px;

}
