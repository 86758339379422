@import '../../../styleSheets/globalColors.scss';

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: $gray900;
  text-align: left;
}

.countSection {
  display: flex;
  align-items: center;
}
.count {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: black;
}

.invalid {
  color: $invalid;
}

.images {
  display: flex;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
}

.icon {
  cursor: pointer;
  display: block;
  padding: 4px 16px;

  background: #F1ECE4;
  border-radius: 100px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: $gold900;
  -webkit-tap-highlight-color: transparent;
}
