
label.group-menu-label {
  .group-menu-username {
    display: inline-box;
    text-align: left;
    padding: 0px 13px;
    line-height: 48px;
    height: 48px;
    border-bottom: 1px solid #eee;
    flex-grow: 1;

    .from-line {
      color: gray;
      font-size: 10px;
    }
  }

  .group-menu-radio {
    padding-right: 11px;
  }

  .group-menu-username.disabled {
    border-bottom: 1px solid transparent;
  }

  .group-menu-username.select-all {
    position: relative;
  }

  .group-menu-username.select-all > div {
    position: relative;
    bottom: 4px;
  }
}
.group-menu-image-block {
  display: flex;
  vertical-align: bottom;

  .group-menu-userimage {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    vertical-align: bottom;
  }

  .fa-user {
    position: relative;
    left: 3px;
    border-radius: 50%;
    display: block;
    vertical-align: bottom;
    color: #ccc;
    font-size: 40px;
    width: 40px;
    height: 40px;
  }
}

