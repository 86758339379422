@import '../../../styleSheets/globalColors.scss';

.container {
  height: 100%;
  flex-flow: column;
  display: flex;
}

.labels {
  flex-shrink: 1;
  overflow: auto;
  flex-grow: 1;
}

.header {
  max-height: 52px;
  height: 52px;
  padding: 17px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;

  .summaryAmount {
    text-overflow: ellipsis;
    padding-left: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }
}

.headerLabel {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: black;
  align-items: center;
}

.checkboxClose {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: $gold700;
  cursor: pointer;
}

.selectAll {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  color: black;
}

.selectAllRow {
  position: relative;
  flex-flow: row nowrap;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentAllocationTypeRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-right: 8px;
}

.paymentAllocationType {
  border: 1px solid $gray100;
  border-radius: 22px;
  background: $gray100;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  width: 64px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  color: $gray700;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 8px;

  margin-right: 12px;
  font-style: normal;
  text-align: center;
  cursor: pointer;
}


.allocationTypeActive {
  color: $gold700;
  border: 1px solid #A58341;
}

.remainAmount {
  text-overflow: ellipsis;
  padding-top: 2px;
  padding-left: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.summary {
  font-family: 'Noto Sans TC', sans-serif;
  overflow: hidden;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: $green500;
  flex-shrink: 1;
}

.invalid {
  color: $invalid;
}
